import request from '@/utils/request.js'

// 问卷内容暂存
export function tempSave(data) {
  return request({
    url: '/doftec/admin/temp/storage/save',
    method: 'post',
    data: data,
    headers: {
			'Content-Type': 'application/json',
    },
  })
}

// 查询暂存内容
export function getTempSave(params) {
  return request({
    url: '/doftec/admin/temp/storage/query',
    method: 'get',
    params: params
  })
}

// 删除暂存内容
export function delTempSave(params) {
  return request({
    url: '/doftec/admin/temp/storage/delete',
    method: 'get',
    params: params
  })
}