import {
  req
} from "./axiosFun";
// // 获取题目
// const getQuestions = param => get("/question/list", param);
// // 提交答案
// const uploadAnswer = param => post("/question/upload", param);
// export default {
//   getQuestions, // 获取题目
//   uploadAnswer //提交答案
// };

export const getQuestions = (params) => {
  return req("get", "/api/question/list", params)
};
export const uploadAnswer = (params) => {
  return req("post", "/api/question/upload", params)
};

// echarts数据
export const getSurvey = (params) => {
  return req("get", "/api/survey/get", params)
};
export const getSurveyScore = (params) => {
  return req("get", "/api/survey/score", params)
};
// 获取每天的填报情况
export const analysisDate = (params) => {
  return req("get", "/api/analysis/date", params)
};
// 获取地市
export const cityDate = (params) => {
  return req("get", "/api/city/date", params)
};
// 获取职位
export const jobDate = (params) => {
  return req("get", "/api/job/date", params)
};
// 获取样本企业
export const sampleDate = (params) => {
  return req("get", "/api/sample/date", params)
};
// 获取企业
export const companyDate = (params) => {
  return req("get", "/api/company/date", params)
};