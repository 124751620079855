<template>
  <div>
<li v-for="bo in items"  :key="q.code + bo.code" :id="'o-' + q.code + '-' + bo.code">
            <div class="form-check form-check-inline">
                <input type="checkbox"
                   :id="q.code + '-' + bo.code"
                   :class="'form-check-input ' + getClass(q)" 
                   :value="bo.value"   
                   :name="'post[' + q.code + ']'"
                   :disabled="bo.disabled"
                   @change="switch_target($event, bo)"
                   v-model="answer[q.code]"
                >
                <label class="form-check-label" :for="q.code + '-' + bo.code">
                    {{bo.text}}
                </label>
            </div>
        </li>
  </div>
</template>
<script>
export default {
  
    props: {
        q: {},
        answer: {},
        items: []
    },

    computed: {},
    mounted() {

    },
    methods: {
        switch_target(e, bo) {
            this.$emit('switch_target', e, this.q, bo);
        },
        getClass(q) {

            var classBody = "";
            if (q.input_class) {
                classBody += q.input_class
            }

            var valid = []

            //必须
            if (q.input_must) {
                valid.push("required");
            }
            //最多选项
            if (q.input_selected_max) {
                valid.push("maxCheckbox[" + q.input_selected_max + "]");
            }

            //最大数字
            if (q.input_max) {
                valid.push("max[" + q.input_max + "]")
            }

            if (valid) {
                classBody += " validate[" + valid.join(",") + "]"
            }

            return classBody;
        }
    }
}
</script>
<style scoped>

</style>