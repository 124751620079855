<template>
  <div>
     <!-- v-html="title" -->
    <div
      :class="'q-t ' + ((titleHidden && contentHidden)? 'text-decoration-line-through': '')"
      v-if="titleHidden"
			v-html="title" 
      :hide="q.hide"
      :index="q.index"
    >
    
       <!--  <span v-if="q.index">{{q.index}}.</span><span class="text-danger" v-if="q.input_must"> * </span><span v-html="q.title_as?q.title_as:q.title"></span> <el-tag  effect='dark' v-if="q.input_type=='checkbox' || q.input_type=='radio'"> {{q.input_type==='checkbox'?'多选':q.input_type==='radio'?'单选':''}} </el-tag> -->
    </div>
  </div>
</template>

<script>
export default {
  
    props: {
        q: {}
    },
    computed: {
        titleHidden() {
            return this.q.title_hidden === 0
        },
        contentHidden() {
            return this.q.content_hidden === 1
        },
        title() {
            var h = ""

            if (this.q.index) {
                h += this.q.index + ". "
            }
            
            if(this.q.input_type){
                switch (this.q.input_type){
									case "checkbox":
                        h += "<span class=\"badge bg-primary text-white\">多选</span>"
                        break
                    case "radio":
                        h += "<span class=\"badge bg-primary text-white\">单选</span>"
                        break
                }
            }
            if (this.q.input_must) {
                h += '<span class="text-danger"> * </span>'
            }
            if (this.q.title_as) {
                h += this.q.title_as
            } else {
                h += this.q.title
            }
            return h
        }
    },
    methods: {}
}
</script>

<style scoped>
::v-deep .text-danger{
  color:#ed5565
}
.text-decoration-line-through{
    text-decoration: line-through !important;
}
</style>