<template>
    <div>
        <div class="form-check form-check-inline">
            <input type="checkbox"
               :id="q.code + '-' + o.code"
               :class="'form-check-input ' + getClass(q)"               
               :value="o.value"
               :name="'post[' + q.code + ']'"
               :disabled="o.disabled"
               @change="switch_target($event)"
               v-model="answer[q.code]"
            >
            <label class="form-check-label" :for="q.code + '-' + o.code">
                {{o.text}}
            </label>
        </div>
    </div>
</template>
<script>
export default {
    
    props: {
        q: {},
        o: {},
        answer: {}
    },

    computed: {},
    methods: {
        switch_target(e) {
            this.$emit('switch_target', e, this.q, this.o);
        },
        getClass(q) {

            var classBody = "";
            if (q.input_class) {
                classBody += q.input_class
            }

            var valid = []
            //
            if (q.input_must) {
                valid.push("required");
            }

            //最多选项
            if (q.input_selected_max) {
                valid.push("maxCheckbox[" + q.input_selected_max + "]");
            }

            if (q.input_selected_min) {
                valid.push("minCheckbox[" + q.input_selected_min + "]");
            }

            if (valid) {
                classBody += " validate[" + valid.join(",") + "]"
            }

            return classBody
        }
    }
}
</script>