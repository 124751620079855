<template>
  <div>
    <input
      type="number"
      :id="q.code"
      v-if="q.code"
      :class="'form-control ' + q.input_class + getClass() + ' base'"
      :name="'post[' + q.code + ']'"
      :style="q.input_style"
      :disabled="q.hide?true:false"
      :placeholder="q.input_placeholder"
      min="0.00"
      step="0.01"
      @blur="do_input_max"
      v-model="answer[q.code]"
    >
    <div
      :id="q.code + 'help'"
      class="form-text"
      v-if="q.input_help"
      v-html="q.input_help"
    ></div>
  </div>
</template>
<script>
export default {
    
    props: {
        q: {},
        //这样定义变量vue报错
        // step: 0.01,
        answer: {}
    },
    created(){
    },

    computed: {
        min() {
            if (this.q.input_min) {
                return this.q.input_min
            } else {
                return 0
            }
        },
        max() {
            if (this.q.input_max) {
                return this.q.input_max
            }else{
                return 0
            }
        }
    },
    methods: {

        getClass() {
            var q = this.q
            var classBody = "";
            if (q.input_class) {
                classBody += q.input_class
            }

            var valid = []
            //必须
            if (q.input_must) {
                valid.push("required");
            }

            if (q.input_maxlength) {
                valid.push("maxSize[" + q.input_selected_max + "]");
            }

            //最大数字
            if (q.input_max) {
                valid.push("max[" + q.input_max + "]")
            }

            if (q.input_min) {
                valid.push("min[" + q.input_min + "]")
            }

            if (valid) {
                classBody += " validate[" + valid.join(",") + "]"
            }

            return classBody
        },
        do_input_max(e) {
            console.log("do_input_max", e)
            //this.$emit('do_input_max', this.q);
            //直接调用父级
            this.$parent.do_input_max(this.q);
        }
    }
}
</script>
<style scoped>
.base{
    height: 35px;
    width: 100%;
    padding: 0 12px;
    box-sizing: border-box;
    
}
input{
    outline-color:#409EFF;
}
</style>