<template>
  <div>
    <textarea
      rows="3"
      :id="q.code"
      v-if="q.code"
      :class="'form-control ' + q.input_class + getClass() + ' base'"
      :name="'post[' + q.code + ']'"
      :style="q.input_style"
      :disabled="q.hide?true:false"
      :placeholder="q.input_placeholder"
      v-model="answer[q.code]"
    ></textarea>
    <div
      :id="q.code + 'help'"
      class="form-text"
      v-if="q.input_help"
      v-html="q.input_help"
    ></div>
  </div>
</template>
<script>
export default {
    props: {
        q: {},
        answer: {}
    },

    computed: {},
    methods: {

        getClass() {
            return (this.q.input_must ? ' validate[required]' : '')
        }
    }

}
</script>
<style scoped>
.base{
   /* height: 35px; */
    width: 100%;
    padding: 8px 12px;
    box-sizing: border-box;
}
textarea{
    outline-color:#409EFF;
}
</style>