<template>
  <div>
    <input
      type="text"
      :id="q.code"
      v-if="q.code"
      :class="'form-control ' + q.input_class + getClass(q) + ' base'"
      :name="'post[' + q.code + ']'"
      :style="q.input_style"
      :disabled="q.hide?true:false"
      :placeholder="q.input_placeholder"
      @input="do_input_max"
      v-model="answer[q.code]"
    >
    <div
      :id="q.code + 'help'"
      class="form-text"
      v-if="q.input_help"
      v-html="q.input_help"
    ></div>
  </div>
</template>
<script>
export default {
    props: {
        q: {},
        answer: {}
    },
    computed: {},
    created(){
    },
    methods: {
        getClass(q) {
            var classBody = "";
            if (q.input_class) {
                classBody += q.input_class
            }
            var valid = []
            //必须
            if (q.input_must) {
                valid.push("required");
            }

            if (q.input_maxlength) {
                valid.push("maxSize[" + q.input_maxlength + "]");
            }

            if (valid) {
                classBody += " validate[" + valid.join(",") + "]"
            }


            return classBody
        },
        do_input_max() {
            console.log("doInputMax-input", this.q)
            // this.$parent.do_input_max(this.q);
        }
    }
}
</script>
<style scoped>
.base{
    height: 35px;
    width: 100%;
    padding: 0 12px;
    box-sizing: border-box;
    
}
input{
    outline-color:#409EFF;
}
</style>
