<template>
    <div>
        <select
            :id="q.code" v-if="q.code"
            :class="'form-select ' + getClass(q) +' base'"          
            :name="'post[' + q.code + ']'"
            @change="switch_select($event)"
            v-model="answer[q.code]"
        >
            <option value="" v-if="q.input_options.placeholder">{{q.input_options.placeholder}}</option>
            <option v-for="(o, k) in options"  :key="k + o.code"  :value="o.value" >{{o.text}}</option>
        </select>
        <div :id="q.code + 'help'" class="form-text" v-if="q.input_help" v-html="q.input_help"></div>
    </div>
</template>
<script>
export default {
    
    props: {
        q: {},
        answer: {},
        options: []
    },

    computed: {},
    methods: {
        switch_select(e) {
            this.$emit('switch_select', e, this.q);
        },
        getClass(q){
            return (q.input_must? ' validate[required]': '')
        }
    }
}
</script>
<style scoped>
.base{
    width: 280px;
    height: 40px;
}
select{
    outline-color: #409EFF;
}
</style>