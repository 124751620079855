<template>
  <div>
    <div :class="'form-check form-check-inline' + ((answer[q.code] === o.value)?' bg-light':'')">
            <input type="radio"
                :id="q.code + '-' + o.code"
               :class="'form-check-input' + getClass(q)"               
               :value="o.value"
               :name="'post[' + q.code + ']'"
               :disabled="o.disabled"
               @change="switch_target($event)"
               v-model="answer[q.code]"
            >
            <label class="form-check-label" :for="q.code + '-' + o.code">
                {{o.text}}
            </label>
        </div>
  </div>
</template>
<script>
export default {
  props: {
        q: {},
        o: {},
        answer: {}
    },

    computed: {},
    
    methods: {
        switch_target(e) {
            console.log("vueSurveyLiRadio")
            this.$emit('switch_target', e, this.q, this.o);
        },
        getClass(q){
            return (q.input_must? ' validate[required]': '')
        }
    }
}
</script>